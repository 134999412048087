
/*!
 * classie - class helper functions
 * from bonzo https://github.com/ded/bonzo
 *
 * classie.has( elem, 'my-class' ) -> true/false
 * classie.add( elem, 'my-new-class' )
 * classie.remove( elem, 'my-unwanted-class' )
 * classie.toggle( elem, 'my-class' )
 */

/*jshint browser: true, strict: true, undef: true */
/*global define: false */

( function( window ) {

    'use strict';

// class helper functions from bonzo https://github.com/ded/bonzo

    function classReg( className ) {
        return new RegExp("(^|\\s+)" + className + "(\\s+|$)");
    }

// classList support for class management
// altho to be fair, the api sucks because it won't accept multiple classes at once
    var hasClass, addClass, removeClass;

    if ( 'classList' in document.documentElement ) {
        hasClass = function( elem, c ) {
            return elem.classList.contains( c );
        };
        addClass = function( elem, c ) {
            elem.classList.add( c );
        };
        removeClass = function( elem, c ) {
            elem.classList.remove( c );
        };
    }
    else {
        hasClass = function( elem, c ) {
            return classReg( c ).test( elem.className );
        };
        addClass = function( elem, c ) {
            if ( !hasClass( elem, c ) ) {
                elem.className = elem.className + ' ' + c;
            }
        };
        removeClass = function( elem, c ) {
            elem.className = elem.className.replace( classReg( c ), ' ' );
        };
    }

    function toggleClass( elem, c ) {
        var fn = hasClass( elem, c ) ? removeClass : addClass;
        fn( elem, c );
    }

    var classie = {
        // full names
        hasClass: hasClass,
        addClass: addClass,
        removeClass: removeClass,
        toggleClass: toggleClass,
        // short names
        has: hasClass,
        add: addClass,
        remove: removeClass,
        toggle: toggleClass
    };

    // transport
    if ( typeof define === 'function' && define.amd ) {
        // AMD
        define( classie );
    } else {
        // browser global
        window.classie = classie;
    }

})( window );

/**
 * uiMorphingButton v1.0.0
 */
;( function( window ) {

    'use strict';

    function extend( a, b ) {
        for( var key in b ) {
            if( b.hasOwnProperty( key ) ) {
                a[key] = b[key];
            }
        }
        return a;
    }

    function UIMorphingButton( el, options ) {
        this.el = el;
        this.options = extend( {}, this.options );
        extend( this.options, options );
        this._init();
    }

    UIMorphingButton.prototype.options = {
        onBeforeOpen : function() { return false; },
        onAfterOpen : function() { return false; },
        onBeforeClose : function() { return false; },
        onAfterClose : function() { return false; }
    };



    UIMorphingButton.prototype._init = function() {
        // the button
        this.button = this.el.querySelectorAll( '.morph-header' );
        this.buttonClose = this.el.querySelectorAll( '.morph-close' );
        // state
        this.expanded = false;
        // content el
        this.contentWrapper = this.el.querySelector( '.morph-expand' );
        this.contentHeader = this.el.querySelector( '.morph-header' );
        this.contentHidden = this.el.querySelector( '.morph-hidden' );
        // init events
        this._initEvents();
    };

    UIMorphingButton.prototype._initEvents = function() {
        var self = this;
        var buttons = this.button;
        for (var i = 0; i < buttons.length; i++) {
            buttons[i].addEventListener('click', function() {
                self.openModule();
            });
        }
        var buttonsClose = this.buttonClose;
        for (var j = 0; j < buttonsClose.length; j++) {
            buttonsClose[j].addEventListener('click', function() {
                self.closeModule();
            });
        }
    };
    UIMorphingButton.prototype.openModule = function() {
        var self = this;
        if( self.isAnimating ) return false;
        if( self.expanded ) return false;


        var originPos = self.el.getBoundingClientRect();
        var right = $(window).outerWidth()-(originPos.right);

        var image = $(self.el).find('.morph-header-image');
        var title = $(self.el).find('.morph-header-title');
        var close = $(self.el).find('.morph-button');
        var hardEase = [ .76, .01, 0.91, 1 ];
        var softEase = [ 0, 0, 0, 1 ];

        var time = 3000;
        var dur = function( number ) {
            return time * number
        };
        //OPEN MODULE
        if($('#video').data('ytPlayer') && $('#video').data('ytPlayer').player) $('#video').data('ytPlayer').player.pauseVideo();
        if($('#video2').data('ytPlayer') && $('#video2').data('ytPlayer').player) $('#video2').data('ytPlayer').player.pauseVideo();


        //before
        self.options.onBeforeOpen();
        self.isAnimating = true;
        classie.addClass(self.el, 'open');

        //starting position
        $(self.contentWrapper).css({
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: originPos.bottom + 'px',
            width: '100%',
            minHeight: '100%'
        });
        $(self.contentHeader).css({
            position: 'fixed',
            left: originPos.left + 'px',
            top: originPos.top + 'px',
            bottom: originPos.bottom + 'px',
            width:  originPos.width + 'px',
            height: originPos.height + 'px'
        });
        $(image).css({
            position: 'absolute',
            top : 'auto',
            bottom: 0,
            left : 0,
            right: 0,
            margin: '0 auto',
            height: originPos.height + 'px',
            width: originPos.width + 'px',
            maxWidth: '100%',
            maxHeight: '100%'
        });


        var hiddenright;
        var hiddenleft;
        if(Math.ceil(right) < Math.ceil(originPos.left)) {
            hiddenright = '0';
            //console.log('right');
        } else if (Math.ceil(originPos.left) < Math.ceil(right)) {
            hiddenleft = '0';
            //console.log('left');
        }

        $(self.contentHidden).css({
            display: 'block',
            opacity: 1,
            height: 0,
            marginLeft: hiddenleft ? hiddenleft : 'auto',
            marginRight: hiddenright ? hiddenright : 'auto',
            width: originPos.width + 'px'
        });


        $(self.contentHeader).velocity({
            left: 0,
            right:0,
            width: '100%',
            bottom: originPos.bottom + 'px',
            height: originPos.top+originPos.height +'px',
            top: 0
        }, {
            queue: false,
            duration:dur(.2),
            easing: hardEase,
            begin: function (elements) {
                $(self.contentHeader).css({
                    'position': 'relative'
                });
            }
        }).velocity({
            height: originPos.height
        }, {
            queue: false,
            duration:dur(.2),
            delay: dur(.15),
            easing: [ .36,0,.21,1 ]
        });

        $(self.contentHidden).velocity({
            left: 0,
            right: 0,
            width: '100%'
        }, {
            queue: false,
            duration:dur(.2),
            easing: hardEase
        }).velocity({
            minHeight: '100%'
        }, {
            queue: false,
            duration:dur(.2),
            delay: dur(.15),
            easing: softEase,
            begin: function ( elements ) {
                $(self.contentWrapper).css({
                    bottom: 0
                });
                $(self.contentHidden).children().children().each(function(i, item){
                    var delay = 100 * i;

                    $(item).velocity("fadeIn", { duration: 500, delay:delay });
                });
            },
            complete: function ( elements ){
                $(self.contentHidden).css({
                    marginLeft: '',
                    marginRight: '',
                    height:'auto'
                });
                //END OF
                self.options.onAfterOpen();
                self.isAnimating = false;
                self.expanded = true;
                $(self.contentHidden).children().children().each(function(i, item){
                      $(item).addClass('showContent');
                    var thisItem = item;
                    if($(thisItem).hasClass('videoWrapper') && $(thisItem).children('iframe').length == 0){
                        var src = $(item).data("src");
                        $('<iframe>', {
                            src: src,
                            id:  'iframe'+i,
                            frameborder: 0,
                            scrolling: 'no',
                            allowfullscreen: true,
                            opacity:0
                        }).appendTo($(item));
                    }
                });
            }
        });

        close.velocity({
            rotateZ: '45deg',
            fontSize: '3rem'
        }, {
            queue: false,
            duration:dur(.1),
            delay:dur(0),
            easing: hardEase,
            begin: function (elements) {
                $(elements[0]).css({
                    zIndex: 20000
                });
            },
            complete: function (elements) {
            }
        });
    };

    UIMorphingButton.prototype.closeModule = function() {
        var self = this;
        if( self.isAnimating ) return false;
        if( !self.expanded) return false;


        var originPos = self.el.getBoundingClientRect();
        var right = $(window).outerWidth()-(originPos.right);

        var image = $(self.el).find('.morph-header-image');
        var title = $(self.el).find('.morph-header-title');
        var close = $(self.el).find('.morph-button');
        var hardEase = [ .76, .01, 0.91, 1 ];
        var softEase = [ 0, 0, 0, 1 ];

        var time = 3000;
        var dur = function( number ) {
            return time * number
        };

        //CLOSE MODULE
        $(self.contentHidden).children().children().each(function(i, item){
            var thisItem = item;
            var tmp_delay = 50 * i;
            var delay = Math.max(200-tmp_delay,0);
            $(thisItem).velocity({opacity:0}, { duration: dur(.05), delay:delay, complete: function( elements ){
                $(thisItem).removeAttr( 'style' );
                    $(item).removeClass('showContent');
                // Reset youtube player
                if($(thisItem).hasClass('videoWrapper')) {
                    var leg = $(item).children('iframe').attr("src");
                    $(thisItem).children('iframe').attr("src",leg);
                }
            }});
        });

        close.velocity({
            rotateZ: '0deg',
            fontSize: '1.7rem'
        }, {
            queue: false,
            duration:dur(.15),
            delay:200,
            easing: hardEase,
            begin: function (elements) {
            },
            complete: function (elements) {
            }
        });

        $(self.contentWrapper).velocity({
            width: originPos.width + 'px',
            left: originPos.left + 'px' ,
            right: right + 'px' ,
            top: originPos.top + 'px',
            bottom: originPos.bottom + 'px',
            height: originPos.height + 'px'
        }, {
            easing: [ .53, 0, .49, 1 ],
            duration:dur(.16),
            delay:200,
            begin: function(elements) {
                $(self.contentWrapper).css({
                    position: 'fixed',
                    minHeight: 'auto',
                    overflow: 'hidden'
                });
                self.options.onBeforeClose();
                self.isAnimating = true;
            },
            complete: function(elements) {

                //clear everything
                $([self.contentHeader,self.contentWrapper,self.contentHidden]).removeAttr( 'style' );
                $(image).removeAttr( 'style' );
                $(title).removeAttr( 'style' );
                $(close).removeAttr( 'style' );

                self.isAnimating = false;
                classie.removeClass( self.el, 'open' );
                self.options.onAfterClose();
                self.expanded = false;
                $('#video').data('ytPlayer').player.playVideo();
            }
        });

    };

    // add to global namespace
    window.UIMorphingButton = UIMorphingButton;

})( window );


$(function() {


	var docElem = window.document.documentElement, didScroll, scrollPosition;

    // trick to prevent scrolling when opening/closing button
    function noScrollFn() {
        window.scrollTo( scrollPosition ? scrollPosition.x : 0, scrollPosition ? scrollPosition.y : 0 );
    }

    function noScroll() {
        window.removeEventListener( 'scroll', scrollHandler );
        window.addEventListener( 'scroll', noScrollFn );
    }

    function scrollFn() {
        window.addEventListener( 'scroll', scrollHandler );
    }

    function canScroll() {
        window.removeEventListener( 'scroll', noScrollFn );
        scrollFn();
    }

    function scrollHandler() {
        if( !didScroll ) {
            didScroll = true;
            setTimeout( function() { scrollPage(); }, 60 );
        }
    }

    function scrollPage() {
        scrollPosition = { x : window.pageXOffset || docElem.scrollLeft, y : window.pageYOffset || docElem.scrollTop };
        didScroll = false;
    }

    scrollFn();

	  $( '.morph' ).each(function( index, element ) {
          new UIMorphingButton( element, {
            onBeforeOpen : function() {
              // don't allow to scroll
              noScroll();
            },
            onAfterOpen : function() {
              // can scroll again
              canScroll();
              // add class "noscroll" to body
              classie.addClass( document.body, 'noscroll' );
              // add scroll class to main el
              classie.addClass( element, 'scroll' );
            },
            onBeforeClose : function() {
              // remove class "noscroll" to body
              classie.removeClass( document.body, 'noscroll' );
              // remove scroll class from main el
              classie.removeClass( element, 'scroll' );
              // don't allow to scroll
              noScroll();
            },
            onAfterClose : function() {
              // can scroll again
              canScroll();
            }
          });
    });


});
