/*global jQuery */
/*!
* FitText.js 1.2
*
* Copyright 2011, Dave Rupert http://daverupert.com
* Released under the WTFPL license
* http://sam.zoy.org/wtfpl/
*
* Date: Thu May 05 14:23:00 2011 -0600
*/

(function( $ ){

    $.fn.fitText = function( kompressor, options ) {

        // Setup options
        var compressor = kompressor || 1,
            settings = $.extend({
                'minFontSize' : Number.NEGATIVE_INFINITY,
                'maxFontSize' : Number.POSITIVE_INFINITY
            }, options);

        return this.each(function(){

            // Store the object
            var $this = $(this);

            // Resizer() resizes items based on the object width divided by the compressor * 10
            var resizer = function () {
                $this.css('font-size', Math.max(Math.min($this.width() / (compressor*10), parseFloat(settings.maxFontSize)), parseFloat(settings.minFontSize)));
            };

            // Call once to set.
            resizer();

            // Call on resize. Opera debounces their resize by default.
            $(window).on('resize.fittext orientationchange.fittext', resizer);

        });

    };

})( jQuery );

$(function () {


    function hero(){
        var text = $('.video-text'),
            container = $('.video-container'),
            button = $('.video-play'),
            nav = $('nav');

        //reset
        text.css({marginTop:''});
        container.css({minHeight:''});
        button.css({marginTop: '',marginBottom: ''});


        var wH = $(window).innerHeight(),
            wW = $(window).innerWidth(),
            textHeight = text.outerHeight(),
            buttonHeight = button.outerHeight(),
            navHeight = nav.outerHeight();
            content = Math.max(textHeight-buttonHeight,0),
            containerHeight = wH-navHeight;

            // Limit to pad and up
            textMargin = - + (content/2);
            text.css({marginTop: textMargin});
            container.css({minHeight:containerHeight});

            //target movie and make it not fitToBackground
            //move text under and menu above. Position relative
    }



    var loop;
    function videoInit() {
        var tag = document.createElement('script');

        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        $('#video').YTPlayer({
            fitToBackground: true,
            videoId: 'dhBsLv4RAoM',
            pauseOnScroll: false,
            playerVars: {
                modestbranding: 0,
                autohide: 1,
                autoplay: 1,
                controls: 0,
                showinfo: 0,
                mute: 1,
                wmode: 'transparent',
                branding: 0,
                rel: 0,
                origin: window.location.origin,
                disablekb: 1,
                fs: 0,
                enablejsapi: 1
            },
            callback: function() {
                videoCallbackEvents();
            }
        });
    }
    function videoCallbackEvents() {
        loop = $('#video').data('ytPlayer').player;

        loop.addEventListener('onStateChange', function(event) {

            // OnStateChange Data
            if (event.data === 1) {
                $('.placeholder-image').fadeOut('fast');
                $('body').addClass('ready');
            } else if (event.data === 0) {
            } else if (event.data === 2) {
            }
        });
    }


    var master;
    function video2Init (){
    //wCdE_NCNMrI
        $('#video2').YTPlayer({
            fitToBackground: true,
            videoId: 'iWRw_yivB9w',
            pauseOnScroll: false,
            playerVars: {
                modestbranding: 0,
                autohide: 1,
                autoplay: 1,
                controls: 0,
                showinfo: 0,
                wmode: 'transparent',
                branding: 0,
                rel: 0,
                origin: window.location.origin,
                disablekb: 1,
                fs: 0,
                enablejsapi: 1
            },
            callback: function() {
                master = $('#video2').data('ytPlayer').player;
                initMasterMovie();
                master.unMute();
                master.setVolume(50);
                master.addEventListener('onStateChange', function(event) {
                    //console.log("Player State Change", event);

                    // OnStateChange Data
                    if (event.data === 1) {
                        startMasterMovie();
                        //console.log('video2 plays');
                        if(master.isMuted()){
                            mute_toggle.removeClass('isMute');
                        }
                    } else if (event.data === 0) {
                        //console.log('video2 ended');
                        endMasterMovie();
                    } else if (event.data === 2) {
                        showContent();
                        //console.log('video2 paused');
                    }
                });
            }
        });
    };
    // Custom player
    var time_update_interval;
    function initMasterMovie(){
        // Update the controls on load
        updateTimerDisplay();
        updateProgressBar();

        // Clear any old interval.
        if(time_update_interval) clearInterval(time_update_interval);

        // Start interval to update elapsed time display and
        // the elapsed part of the progress bar every second.
        time_update_interval = setInterval(function () {
            updateTimerDisplay();
            updateProgressBar();
        }, 1000)
    }
    // This function is called by initialize()
    function updateTimerDisplay(){
        // Update current time text display.
        $('#current-time').text(formatTime( master.getCurrentTime() ));
        $('#duration').text(formatTime( master.getDuration() ));
    }

    function formatTime(time){
        time = Math.round(time);

        var minutes = Math.floor(time / 60),
            seconds = time - minutes * 60;

        seconds = seconds < 10 ? '0' + seconds : seconds;

        return minutes + ":" + seconds;
    }
    $('#progress-bar').on('mouseup touchend', function (e) {
        var $this = $(this);
        // Calculate the new time for the video.
        // new time in seconds = total duration in seconds * ( value of range input / 100 )
        var x = e.pageX - $(this).offset().left;
        var percent =  x / $(this).width();
        var newTime = master.getDuration() * percent;

        // Skip video to new time.
        master.seekTo(newTime);

    });
    $('#mute-toggle').on('click', function(e) {
        e.preventDefault();
        var mute_toggle = $(this);

        if(master.isMuted()){
            master.unMute();
            mute_toggle.removeClass('isMute');
        }
        else{
            master.mute();
            mute_toggle.addClass('isMute');
        }
    });
    $('#pause').on('click', function(e) {
        e.preventDefault();
        master.pauseVideo();
    });
    $('#volume-input').on('change', function () {
        if(master.isMuted()){
            master.unMute();
            $('#mute-toggle').removeClass('isMute');
        }
        master.setVolume($(this).val());
    });

    // This function is called by initialize()
    function updateProgressBar(){
        // Update the value of our progress bar accordingly.
        var percent = (master.getCurrentTime() / master.getDuration()) * 100;
        $('#progress-bar .bar').css('width',percent+'%');
    }



    function startMasterMovie() {
        $('.overlay-layer').fadeOut();
        $('.arrowdown').hide();
        $('.video-text').hide();
        loop.pauseVideo();
        $('#video2').show();
        $('.player-controls').fadeIn();
    }
    function endMasterMovie() {
        master.seekTo(1);
        master.pauseVideo();
        showContent();
    }
    function showContent () {
        $('.overlay-layer').fadeIn();
        $('.arrowdown').fadeIn();
        $('.video-text').fadeIn();
        $('.player-controls').hide();
    }

    $('.video-play').on('click', function(e){
        e.preventDefault();
        if(!master) {
            video2Init();
        } else {
            master.playVideo();
        }

    });

    function verticalText(){
        $('.card-text blockquote').each(function(){
            var $this = $(this),
                newTop = ($this.outerHeight())*-1;
            $this.css({'margin-top': newTop/2})
        })
    }

    $(".video-text h1").fitText(1.5, { minFontSize: '14px', maxFontSize: '100px' });

    setTimeout(function(){
        $('body').addClass('ready');
    },1500);

    //Init and resize
    hero();
    verticalText();
    videoInit();
    $( window ).on('resize',function() {
        hero();
        verticalText();
    });

});

